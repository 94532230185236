/**
 * Default configurations for the widget by brand
 * This configurations are production props, ready to use instead of passing each property.
 *
 * @param {Object} baseConfig - Base configuration for the widget
 * @param {Object} defaultConfigs - Default configurations for each brand
 * @returns {Object} - Default configurations for the widget by brand
 */

const baseConfig = {
  maxDaysSearch: '90',
  showOpenTicket: 'false',
  autocompleteType: 'select',
  displayType: 'state',
  allowHybridTrip: 'true',
  calendarOpen: 'true',
  growthbookStandalone: 'true',
  growthbookDev: 'false',
  growthbookFeatures: 'show_open_ticket_cta',
  optInReturn: 'false',
  profileUrl: 'https://core-api.reservamossaas.com/api/v1',
  profileEnabled: 'true',
  profileAutocomplete: 'true',
  groupPlaces: 'true',
  compactHeight: 'false',
  interestEvent: 'Interest In Home',
  passengersDropdown: 'true',
  passengersOptions: 'general,minor,older,wheelchair_handicap,pet_friendly,student,teacher',
  maxPassengers: '10',
  allowFetch: 'true',
  forceHttps: 'true',
  maxRecentSearches: '2',
};

const defaultConfigs = {
  etn: {
    ...baseConfig,
    primaryColor: '#0C3D5C',
    accentColor: '#7BA7AE',
    departureDatePickerText: 'Compra tu boleto de regreso con hasta 10% de descuento',
    returnDatePickerText: 'Compra tu boleto de regreso con hasta 10% de descuento',
    contrast: 'true',
  },
  costaline: {
    ...baseConfig,
    primaryColor: '#0087ca',
    accentColor: '#003b73',
    departureDatePickerText: 'Compra tu boleto de regreso con hasta 15% de descuento',
    returnDatePickerText: 'Compra tu boleto de regreso con hasta 15% de descuento',
  },
  gho: {
    ...baseConfig,
    widgetTitle: 'Nos hemos renovado para ti, conoce nuestra nueva experiencia de compra',
    primaryColor: '#789F90',
    accentColor: '#115E67',
    departureDatePickerText: 'Compra tu boleto de regreso con hasta 15% de descuento',
    returnDatePickerText: 'Compra tu boleto de regreso con hasta 15% de descuento',
    contrast: 'true',
    reverseVariant: 'true',
  },
};

export default defaultConfigs;
