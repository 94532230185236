import { selectIcon } from '../templates/place';
import { createPortal } from 'preact/compat';
import getProductType from '../utils/getProductType';
import { useEffect } from 'preact/hooks';

const Modal = ({ children, onClose, showCloseButton, title, isReduceWidth, isFitContent }) => {
  const isMobile = getProductType() === 'web-mobile';

  // Effect to detect orientation change - only on mobile devices
  useEffect(() => {
    // Only apply this logic on mobile devices and if there is an onClose function
    if (!onClose || !isMobile) return;
    

    const handleOrientationChange = () => {      
      // Force direct close without going through event checks
      onClose('orientationChange');
      
      // As a fallback, simulate click on overlay
      setTimeout(() => {
        const overlay = document.getElementById('modalOverlay');
        if (overlay) {
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          clickEvent.forceClose = true;
          overlay.dispatchEvent(clickEvent);
        }
      }, 100);
    };

    screen.orientation.addEventListener('change', handleOrientationChange);

    return () => {
      screen.orientation.removeEventListener('change', handleOrientationChange);
    };
  }, [onClose, isMobile]);

  const handleOnClick = ({ target }) => {
    const { id } = target;
    if (onClose && (id === 'modalOverlay' || id === 'closeModalButton')) {
      onClose();
    }
  };

  const modal = (
    <div className="sw-overlay" id="modalOverlay" onClick={handleOnClick}>
      <div
        className={`sw-modal ${isReduceWidth ? 'sw-modal-width' : ''} ${
          isFitContent ? 'sw-modal-height' : ''
        }`}
      >
        <div className="sw-modal-content">
          <div className="sw-modal-head">
            {title && <p className="sw-modal-title">{title}</p>}

            {showCloseButton && (
              <div
                className="sw-modal-close"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: selectIcon('close', false),
                }}
                id="closeModalButton"
                onClick={onClose}
              />
            )}
          </div>

          {children}
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    const portalRoot = document.getElementById('reservamosPortal');
    return createPortal(modal, portalRoot);
  }

  return modal;
};

export default Modal;
