/* global $ */
import { Component, Fragment } from 'preact';
import { Localizer, Text, withText } from 'preact-i18n';
import dayjs from 'dayjs';
import Datepicker from 'async!./datepicker';
import BannerBranding from 'async!./BannerBranding';
import RoundSwitch from 'async!./RoundSwitch';
import { initTest } from '../utils/abtest';
import validate from '../utils/validate';
import resultsUrl from '../utils/resultsUrl';
import decodePassengers, { getPassengersSelectorInitialState } from '../utils/decodePassengers';
import searchFromQuery from '../utils/searchFromQuery';
import validateDate from '../utils/validateDate';
import stringToDate from '../utils/stringToDate';
import createUrl from '../utils/createUrl';
import gtmSetDataLayer from '../utils/gtmSetDataLayer';
import mixpanelTracker from '../utils/mixpanelTracker';
import WidgetTitle from './WidgetTitle';
import '../style.scss';
import getCalendarRanges from '../utils/getCalendarRanges';
import PaymentMethods from './PaymentMethods';
import WhatsappCta from './WhatsappCta';
import WhatsappBubble from './WhatsappBubble';
import { getRecentSearches, saveRecentSearches } from '../utils/recentTrips';
import ReverseRoutesButton from './ReverseRoutesButton';
import { camelizeKeys } from 'humps';
import { withUserPreferences } from '../context/userPreferences/';
import { selectIcon } from '../templates/place';
import http from '../services/http/';
import { withWidgetConfig } from '../context/WidgetConfig';
import { withGrowthBookFeatures } from '../context/GrowthBook';
import PassengersSelector from './PassengersSelector';

const getInterestInSearchEventHandler = (section, additionalProps = {}) => () => {
  mixpanelTracker.trackInterestInSearchEvent({
    section,
    ...additionalProps,
  });
};

class Search extends Component {
  state = {
    errors: {},
    passengers: {},
    tripType: 'oneWay',
    hybridTrip: 'none', // none, departure, return, both
    usingNearbyTerminal: false,
    loadingNearbyTerminal: false,
    autoOpenCalendar: false,
  };

  fieldClasses = {
    origin: 'input.origin',
    destination: 'input.destination',
    departureDate: 'input.departureDate',
    returnDate: 'input.returnDate',
  };

  constructor(props) {
    super(props);

    this.debounce = null;
    this.showPaymentMethods = true;
    this.showDiscountsAB = true;
    this.showTextPromAB = true;
    this.usingNearbyTerminal = false;

    this.onInputChange = this.onInputChange.bind(this);
    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.onChangePassengers = this.onChangePassengers.bind(this);
    this.onChangeDepartureDate = this.onChangeDepartureDate.bind(this);
    this.onChangeReturnDate = this.onChangeReturnDate.bind(this);
    this.setGtmProperties = this.setGtmProperties.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeDepartureToOpenTicket = this.onChangeDepartureToOpenTicket.bind(this);
    this.onChangeReturnToOpenTicket = this.onChangeReturnToOpenTicket.bind(this);
    this.onRemoveReturnOpenTicket = this.onRemoveReturnOpenTicket.bind(this);
    this.onRemoveDepartureOpenTicket = this.onRemoveDepartureOpenTicket.bind(this);
    this.setDeparturePicker = this.setDeparturePicker.bind(this);
    this.onDatePickerCreated = this.onDatePickerCreated.bind(this);
    this.reversePlaces = this.reversePlaces.bind(this);
    this.fetchNearTerminals = this.fetchNearTerminals.bind(this);
    this.onFocusSearchButton = this.onFocusSearchButton.bind(this);
    this.onNoSearchResults = this.onNoSearchResults.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.configFlagsOnChangeAutocomplete = this.configFlagsOnChangeAutocomplete.bind(this);
    this.wasAutoPlaceModified = this.wasAutoPlaceModified.bind(this);
    this.didInitialOriginChanged = this.didInitialOriginChanged.bind(this);
    this.isUsingRecentSearch = this.isUsingRecentSearch.bind(this);
    this.isUsingPopularDestinations = this.isUsingPopularDestinations.bind(this);
    this.onDepartureDateSelected = this.onDepartureDateSelected.bind(this);
    this.setPreferredTerminalsFlag = this.setPreferredTerminalsFlag.bind(this);
    this.onResetPassengers = this.onResetPassengers.bind(this);
    this.autoOpenPassengersSelector = this.autoOpenPassengersSelector.bind(this);
    this.handleOnClickSubmit = this.handleOnClickSubmit.bind(this);
    this.clearReturnDate =this.clearReturnDate.bind(this);
    this.clearDepartureDate = this.clearDepartureDate.bind(this);

    // Indicates if a input has been clicked
    this.inputChanged = false;
    this.inputFocused = false;
    this.placeSelected = false;

    this.geoLocationAsked = false;
    this.usingGeoOrigin = false;
    this.geoOriginShowed = false;
    this.departureCalendarOpened = false;
    this.initialOriginChanged = false;

    this.preferredTerminalsFlags = {
      usingOrigin: false,
      usingDestination: false,
      usingRecentSearch: false,
      showed: false,
    };

    this.popularDestinationsUsed = false;
    this.calendarButtonUsed = null;
    this.compactDateUsed = false;
  }

  componentDidMount() {
    this.setInitialState();
    const { config } = this.props;
    const { optInReturn, calendarOpen } = config;

    this.initAbTests();

    if (optInReturn && !calendarOpen) {
      const { tripType } = this.state;

      if (tripType === 'oneWay') {
        this.$get('returnDate').hide();
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ returnDate: null });
      }
    }

    this.emitSearchLoaded();
  }

  componentDidUpdate(prevProps, { tripType: prevTripType }) {
    const { config, growthbookFeatures } = this.props;
    const { optInReturn, calendarOpen } = config;
    const { tripType } = this.state;

    this.autoSelectionManagement();

    if (growthbookFeatures?.search_passengers && !prevProps.growthbookFeatures?.search_passengers) {
      const passengersState = this.getInitialPassengersState();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        passengers: passengersState,
      });
    }

    if (!optInReturn || tripType === prevTripType) return;

    if (tripType === 'round' && !calendarOpen) {
      this.$get('returnDate').show();
    } else if (tripType === 'oneWay') {
      this.$get('returnDate').hide();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.debounce);
  }

  /**
   *
   * @param {String} section - The section of the search that is being focused
   * @param {*} additionalProps - Additional properties to be sent to mixpanel
   * @returns
   */
  onInputFocus = (section, additionalProps = {}) => () => {
    /**
     * This variable is set true to indicate that the user has focused some input,
     * in this way we can avoid to autofill the input and override what the user has wrote
     */
    this.inputFocused = true;
    getInterestInSearchEventHandler(section, additionalProps)();
  };

  onDatePickerCreated(way) {
    const { config } = this.props;
    const { departureDate: initialDepartureDate, returnDate: initialReturnDate } = config;
    const { departureDate } = this.state;

    if (way === 'return' && initialDepartureDate) {
      this.onChangeDepartureDate(departureDate);
    }
    if (way === 'departure' && initialReturnDate) {
      this.onChangeReturnDate(initialReturnDate);
    }
  }

  /**
   * Decides which auto-selection feature should be triggered
   * order: preferences > geolocation origin
   */
  autoSelectionManagement() {
    const { userPreferences, config } = this.props;

    if (!userPreferences.requested || config.origin) return;

    if (!this.inputChanged && !this.preferredTerminalsSetup && userPreferences.originPlace) {
      this.preferredTerminalsSetup = true;
      this.setUpPreferredTerminals();
      return;
    }

    if (!this.geoLocationAsked) {
      this.geoLocationAsked = true;
      this.getGeolocation();
      return;
    }
  }

  /**
   * Set up the terminal preferred by the user,
   * If the user has a preferred terminal, the places
   * that matches with those values will be set as the origin and destination
   */
  setUpPreferredTerminals() {
    const { userPreferences } = this.props;
    const { originPlace, destinationPlace } = userPreferences;

    if (originPlace && destinationPlace) {
      /**
       * This class variables are set true to indicate that at the moment,
       * the preferred places have been showed and used by the user.
       */
      this.preferredTerminalsFlags = {
        usingOrigin: true,
        usingDestination: true,
        showed: true,
      };

      if (!this.placeSelected) {
        this.setState({
          preferredOrigin: originPlace,
          preferredDestination: destinationPlace,
        });
      }
    }
  }

  /**
   * Gets the current geolocation of the user and sets it as the origin
   */
  getGeolocation() {
    const { config } = this.props;
    const { origin, geolocationOrigin, orderByGeoLocation, showNearestTerminal } = config;
    const { coords } = this.state;
    if (coords || origin || (!geolocationOrigin && !orderByGeoLocation && !showNearestTerminal))
      return;

    const GEOLOCATION_TRACK_EVENT = 'Geolocation Requested';
    this.orderByGeoLocationActive = orderByGeoLocation;

    navigator.geolocation.getCurrentPosition(
      geolocation => {
        const { latitude: lat, longitude: long } = geolocation.coords;
        const coords = {
          lat,
          long,
        };
        mixpanelTracker.trackEvent(GEOLOCATION_TRACK_EVENT, { geolocationAccepted: true });
        this.setState({ coords }, () => {
          if (
            (geolocationOrigin && !this.preferredTerminalsFlags.showed && !this.inputChanged) ||
            showNearestTerminal
          ) {
            this.fetchNearTerminals(coords);
          }
        });
      },
      () => {
        mixpanelTracker.trackEvent(GEOLOCATION_TRACK_EVENT, { geolocationAccepted: false });
      }
    );
  }

  fetchNearTerminals(coords) {
    const { config } = this.props;
    const { origin, geolocationOrigin, showNearestTerminal } = config;
    if (origin) return;
    const { lat, long } = coords;
    const sourceUrl = this.getAutoCompleteUrl().href;
    const url = `${sourceUrl}/near-to-user?lat=${lat}&long=${long}`;
    http.get(url).then(data => {
      if (data && data.length > 0) {
        const place = data[0];
        // A flag is set true to know that the origin based on geolocation is showed
        if (!this.preferredTerminalsFlags.showed) {
          this.geoOriginShowed = true;
          this.usingGeoOrigin = true;
        }
        if (
          !this.inputChanged &&
          !this.placeSelected &&
          ((geolocationOrigin && !this.preferredTerminalsFlags.showed) || showNearestTerminal)
        )
          this.setState({
            geoLocationOrigin: camelizeKeys(place),
            initialOriginSlug: place.slug,
          });
      }
    });
  }

  /**
   * Handle the change of the origin or departure place. Event is triggered after the user types.
   * @param {string} field - Field origin or destination
   * @param {string} input - Input value
   */
  onInputChange(field, input) {
    this.inputChanged = true;
    clearTimeout(this.debounce);
    // If the input is empty, we don't want to trigger anything
    if (!input || input === '') return;

    this.debounce = setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        [`${field}SearchAttempts`]: [...prevState[`${field}SearchAttempts`], input],
      }));
    }, 2000);
  }

  /**
   * Checks if the place was change because the user selected or typed a new place
   * @param {Object} autoSelectedPlace - Place that was auto selected
   * @param {Object} newPlace - Place that was selected by the user
   * @returns {Boolean}
   */
  wasAutoPlaceModified({ autSelectedPlace, newPlace }) {
    const inputModified = this.inputChanged || this.inputFocused;
    return autSelectedPlace?.id !== newPlace.id && inputModified;
  }

  /**
   * Checks if the preferred terminals where changed by the user
   * @param {Object} place - Place selected
   * @param {String} field - Field origin or destination
   */
  didPreferredChanged = ({ place, field }) => {
    const { growthbookFeatures } = this.props;
    const { preferredOrigin, preferredDestination } = this.state;
    if (!this.preferredTerminalsFlags.showed || growthbookFeatures.preferredTerminalsDesign) return;

    const isRecentTrip = field === 'recentTrip';

    const originWasChanged = this.wasAutoPlaceModified({
      autSelectedPlace: preferredOrigin,
      newPlace: place,
    });

    const destinationWasChanged = this.wasAutoPlaceModified({
      autSelectedPlace: preferredDestination,
      newPlace: place,
    });

    if (isRecentTrip || (field === 'origin' && (originWasChanged || this.inputChanged))) {
      // If the origin was changed, then the preferred terminals are not used
      if (originWasChanged || this.inputChanged) {
        this.preferredTerminalsFlags.usingOrigin = false;
        this.initialOriginChanged = true;
      }
    }
    if (isRecentTrip || (field === 'destination' && (destinationWasChanged || this.inputChanged))) {
      // If the destination was changed, then the preferred terminals are not used
      this.preferredTerminalsFlags.usingDestination = false;
    }
  };

  /**
   * Checks if the geolocation origin where changed by the user
   * @param {Object} place - Place selected
   * @param {String} field - Field origin or destination
   */
  didGeoOriginChanged = ({ place, field }) => {
    const { geoLocationOrigin } = this.state;
    const geoOriginWasChanged = this.wasAutoPlaceModified({
      autSelectedPlace: geoLocationOrigin,
      newPlace: place,
    });

    if (field === 'origin') {
      // If the origin was changed, then the geolocation origin is not used
      if (geoOriginWasChanged || this.inputChanged) {
        this.usingGeoOrigin = false;
        this.initialOriginChanged = this.geoOriginShowed && true;
      }
    }
  };

  /**
   * Checks if the nearby terminal was used by the user
   * @param {Object} place - Place selected
   * @param {String} field - Field origin or destination
   */
  didSelectedNearbyPlace({ place, field }) {
    const { geoLocationOrigin } = this.state;
    if (field === 'origin') {
      if (place.id === geoLocationOrigin?.id) {
        this.usingNearbyTerminal = true;
      } else {
        this.usingNearbyTerminal = false;
      }
    }
  }

  /**
   * This functions is used to make now the destination input if the initial origin was changed
   * and be able to do some logic with it.
   * @returns {Boolean} - Indicates if the initial origin was changed by the user
   */
  didInitialOriginChanged() {
    return this.initialOriginChanged;
  }

  /**
   * Checks if a preferred terminal was used
   * @param {Object} params - function parameters
   * @param {Object} place - Place object
   */
  setPreferredTerminalsFlag() {
    const { growthbookFeatures } = this.props;
    const { origin, destination } = this.state;

    if (!growthbookFeatures.preferred_terminals_design) return;

    this.preferredTerminalsFlags.usingOrigin = Boolean(origin?.preferred);
    this.preferredTerminalsFlags.usingDestination = Boolean(destination?.preferred);
  }

  /**
   * Executes functions to configure the flags that are used to know if the user has used some features
   * and be able to reflect this with the analytics events or control the behavior of the widget
   * @param {Object} place - Place selected
   * @param {String} field - Field origin or destination
   */
  configFlagsOnChangeAutocomplete(field, place) {
    this.didPreferredChanged({ field, place });
    this.didGeoOriginChanged({ field, place });
    this.didSelectedNearbyPlace({ field, place });
    this.isUsingRecentSearch({ place });
    this.isUsingPopularDestinations({ field, place });
  }

  /**
   * Checks if a popular destination was used
   * @param {Object} params - function parameters
   * @param {String} params.field - Field origin or destination
   * @param {Object} params.place - Place selected
   * @returns
   */
  isUsingPopularDestinations({ field, place }) {
    if (field !== 'destination') return;
    this.popularDestinationsUsed = Boolean(place?.isPopularDestination);
  }

  /**
   * Checks is a recent search is used
   * @param {Object} params = function parameters
   * @param {String} params.field - Field origin or destination
   * @param {Object} params.place - Place selected
   */
  isUsingRecentSearch({ place }) {
    const { origin, destination } = place;
    if (origin?.recentSearch && destination?.recentSearch) {
      this.preferredTerminalsFlags.usingRecentSearch = true;
    } else {
      this.preferredTerminalsFlags.usingRecentSearch = false;
    }
  }

  /**
   * This function auto opens the calendar
   */
  openDepartureCalendar() {
    const { departureDate } = this.state;
    if (!this.inputFocused || departureDate) return;
    this.setState({
      autoOpenCalendar: 'departure',
    });
  }

  /**
   * This function auto opens the passengers selector
   */
  autoOpenPassengersSelector() {
    const {
      config: { passengersForSelector, departureDate },
      growthbookFeatures,
    } = this.props;
    if (!departureDate && passengersForSelector?.length && growthbookFeatures?.search_passengers) {
      this.setState({
        autoOpenPassengersSelector: true,
      });
    }
  }

  /**
   * Handle the change of the origin or departure place. Event is triggered after the user selects a place
   * @param {string} field - Field origin or destination
   * @param {Object} place - Place selected
   */
  onChangeAutocomplete(field, place) {
    this.configFlagsOnChangeAutocomplete(field, place);

    const { origin, destination } = place;
    const currentState = this.state;
    const { errors } = currentState;

    this.placeSelected = Boolean(place.id || origin);

    /**
     * When selecting a recent search or a recommended route, origin and destination
     * will be passed instead of just one place.
     */
    if (origin && destination) {
      this.setState({ origin, destination });
      this.openDepartureCalendar();
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          origin: '',
          destination: '',
        },
      }));
    } else {
      this.setState({ [field]: place });
      if (field === 'origin' && Object.keys(place).length === 0) {
        this.compactDateUsed = false;
        this.clearReturnDate();
        this.clearDepartureDate();
      };
      if (field === 'destination' && Object.keys(place).length) this.openDepartureCalendar();

      if (errors[field] && Object.keys(place).length > 0) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [field]: '',
          },
        }));
      }
    }

    this.setGtmProperties();
  }

  onChangeDepartureToOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'none') {
      this.setState({ hybridTrip: 'departure' });
    } else if (hybridTrip === 'return') {
      this.setState({ hybridTrip: 'both' });
    }
  }

  onChangeReturnToOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'none') {
      this.setState({ hybridTrip: 'return' });
    } else if (hybridTrip === 'departure') {
      this.setState({ hybridTrip: 'both' });
    }
  }

  onRemoveReturnOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'both') {
      this.setState({ hybridTrip: 'departure' });
    } else if (hybridTrip === 'return') {
      this.setState({ hybridTrip: 'none' });
    }
  }

  onRemoveDepartureOpenTicket() {
    const { hybridTrip } = this.state;
    if (hybridTrip === 'both') {
      this.setState({ hybridTrip: 'return' });
    } else if (hybridTrip === 'departure') {
      this.setState({ hybridTrip: 'none' });
    }
  }

  onChangeDepartureDate(departureDate) {
    const { openTicketText, config } = this.props;
    const { allowHybridTrip } = config;
    const isOpenDate = departureDate === openTicketText;
    this.setState({ departureDate });

    if (!isOpenDate) {
      this.onRemoveDepartureOpenTicket();
    }

    // If hybrid trip is not allowed, but departure is open date
    // Then return date should be cleared and disabled
    if (!allowHybridTrip && isOpenDate) {
      this.clearReturnDate();
    }

    if (departureDate !== '') {
      this.compactDateUsed = true;
      this.$get('returnDate').trigger('changeDepartureDate', departureDate);
    }

    const { errors } = this.state;
    if (errors.departureDate && departureDate !== '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          departureDate: '',
        },
      }));
    }

    if (departureDate) {
      this.autoOpenPassengersSelector();
    }

    this.setGtmProperties();
  }

  clearDepartureDate() {
    const event = $.Event('clearDate');
    event.keepCalendarClosed = true;
    this.$get('departureDate').trigger(event);
  }
  
  clearReturnDate() {
    this.setState({ returnDate: '' });
    this.onRemoveReturnOpenTicket();
    this.$get('returnDate').trigger('clearDate');
  }

  onDepartureDateSelected(selectionType) {
    this.calendarButtonUsed = selectionType;
  }

  onChangeReturnDate(returnDate) {
    const {
      openTicketText,
      config: { maxDaysSearch },
    } = this.props;
    this.setState({ returnDate });

    if (returnDate !== openTicketText) {
      this.onRemoveReturnOpenTicket();
    }

    if (returnDate !== '') {
      this.$get('departureDate').trigger('changeReturnDate', returnDate);
    }

    // If the return date is null, it means that the return is not set, and the departure limit is removed
    if (!returnDate) {
      const { max } = getCalendarRanges(maxDaysSearch);
      this.departurePicker.pickadate('picker').set('max', max);
    }

    const { errors } = this.state;
    if (errors.returnDate && returnDate !== '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          returnDate: '',
        },
      }));
    }

    this.setGtmProperties();
  }

  onChangePassengers(type, quantity) {
    const { passengers: currentPassengers } = this.state;
    const passengers = {
      ...currentPassengers,
      [type]: quantity,
    };

    this.setState({ passengers });
    this.setGtmProperties();
  }

  onResetPassengers({ passengers }) {
    const newPassengersValues = passengers.reduce((acc, passenger) => {
      acc = {
        ...acc,
        [passenger.name]: passenger.value,
      };
      return acc;
    }, {});

    this.setState({
      passengers: newPassengersValues,
    });
  }

  handleOnClickSubmit() {
    mixpanelTracker.trackEvent('Search Clicked');
    this.onSubmit();
  }

  setSeatsOnResultQueryParam(url) {
    const { growthbookFeatures } = this.props;
    const seatsOnResultsActive = growthbookFeatures?.results_seats;
    if (!seatsOnResultsActive) return url;
    const seatsOnResultsType = growthbookFeatures?.enable_categories;
    switch (seatsOnResultsType) {
      case 'simple':
        return this.setUrlParam(url, 'seatsOnResults', 'true');
      case 'categories':
        return this.setUrlParam(url, 'seatsOnResultsDiscounts', 'true');
      default:
        return url;
    }
  }

  onSubmit() {
    if (!this.validate()) return;

    const { config, growthbookFeatures } = this.props;
    const { origin, destination } = this.state;
    const { passengersForSelector } = config;

    const searchType = config.secondRedirectEnabled ? 'secondRedirect' : 'bus';

    const funnelUrl = growthbookFeatures?.funnel_version?.funnelUrl;

    if (funnelUrl) {
      config.funnelUrl = funnelUrl;
    }

    const searchFields = {
      ...this.state,
      useCustomPassengers: !!passengersForSelector?.length,
    };

    const url = resultsUrl(searchFields, config, searchType);

    const { openNewWindow, forceHttps, lang, maxRecentSearches, filters } = config;

    let urlFormatted = `${window.location.protocol}${url}`;
    if (forceHttps) urlFormatted = `https:${url}`;

    urlFormatted = this.getAnalyticsParams(urlFormatted);
    urlFormatted = this.getAnalyticsABParams(urlFormatted);

    if (lang) urlFormatted = this.setUrlParam(urlFormatted, 'lang', lang);

    if (filters) urlFormatted = this.setUrlParam(urlFormatted, 'filters', filters);

    if (maxRecentSearches)
      saveRecentSearches(origin, destination, config.maxRecentSearches, config.recentSearchesLS);

    urlFormatted = this.setSeatsOnResultQueryParam(urlFormatted);

    const { triggerSearchEvent } = config;
    if (triggerSearchEvent) {
      const searchEvent = new CustomEvent('SearchWidget:search', {
        detail: {
          absoluteSearchUrl: urlFormatted,
        },
      });
      window.dispatchEvent(searchEvent);
      return;
    }

    if (openNewWindow) {
      window.open(urlFormatted);
    } else {
      window.top.location.href = urlFormatted;
    }
  }

  setUrlParam(url, paramName, param) {
    const newUrl = new URL(url);
    newUrl.searchParams.append(paramName, param);
    return newUrl.toString();
  }

  getAnalyticsABParams(url) {
    const { config } = this.props;
    const { destination } = this.state;
    const { abtestDestinationHit, abtestSelectedTripType } = config;

    // If every item in the array is false, then return url
    if ([abtestDestinationHit, abtestSelectedTripType].every(item => !item)) {
      return url;
    }

    const prefix = 'analytics_';

    const newUrl = new URL(url);
    if (abtestSelectedTripType)
      newUrl.searchParams.append(`${prefix}round_trip_ab`, this.abRoundTrip);

    newUrl.searchParams.append(`${prefix}destination_hit_order_ab`, this.abDestinationHit);
    if (abtestDestinationHit && !this.abDestinationHit) return newUrl.toString();

    newUrl.searchParams.append(`${prefix}destination_hit_order`, destination.position);

    return newUrl.toString();
  }

  getAnalyticsParams(url) {
    const { config } = this.props;
    const { origin, geoLocationOrigin: geolocationOriginPlace } = this.state;
    const { geolocationOrigin, showNearestTerminal } = config;
    const prefix = 'analytics_';
    const newUrl = new URL(url);

    if (geolocationOrigin) {
      newUrl.searchParams.append(`${prefix}geo_place_used`, this.usingGeoOrigin);
      newUrl.searchParams.append(`${prefix}geo_place_showed`, this.geoOriginShowed);
    }

    const nearestTerminalShowed = Boolean(showNearestTerminal && geolocationOriginPlace);
    if (nearestTerminalShowed) {
      newUrl.searchParams.append(`${prefix}nearby_terminal_showed`, nearestTerminalShowed);
      newUrl.searchParams.append(
        `${prefix}nearby_terminal_used`,
        origin.id === geolocationOriginPlace?.id
      );
    }
    this.setPreferredTerminalsFlag();
    const {
      showed: preferredTerminalsShowed,
      usingOrigin: usingPreferredOrigin,
      usingDestination: usingPreferredDestination,
      usingRecentSearch,
    } = this.preferredTerminalsFlags;

    newUrl.searchParams.append(`${prefix}preferred_terminal_showed`, preferredTerminalsShowed);
    newUrl.searchParams.append(`${prefix}recent_search_used`, usingRecentSearch);

    const recentSearches =
      config.maxRecentSearches &&
      getRecentSearches(config.maxRecentSearches, config.recentSearchesLS);
    newUrl.searchParams.append(`${prefix}recent_search_showed`, Boolean(recentSearches.length));

    newUrl.searchParams.append(
      `${prefix}popular_destinations_design_used`,
      this.popularDestinationsUsed
    );

    newUrl.searchParams.append(`${prefix}calendar_button_used`, this.calendarButtonUsed);

    if (preferredTerminalsShowed) {
      newUrl.searchParams.append(
        `${prefix}preferred_terminal_used`,
        Boolean(usingPreferredOrigin || usingPreferredDestination)
      );
    }
    return newUrl.toString();
  }

  setGtmProperties() {
    const { config } = this.props;
    const { origin = {}, destination = {}, departureDate, returnDate, passengers } = this.state;

    if (config.useGtm) {
      gtmSetDataLayer({
        originCity: origin.cityName,
        originState: origin.state,
        originCountry: origin.country,
        destinationCity: destination.cityName,
        destinationState: destination.state,
        destinationCountry: destination.country,
        startDate: stringToDate(departureDate),
        endDate: stringToDate(returnDate),
        travelers: passengers,
      });
    }
  }

  getMixpanelSearchLoadedProps() {
    const { config } = this.props;
    const {
      abtestDestinationHit,
      abtestSelectedTripType,
      paymentBenefitsAB,
      discountsAB,
      textPromAB,
    } = config;

    return {
      ...(abtestDestinationHit && {
        'Destination Hit Order Ab Result': this.abDestinationHit ? 'a' : 'b',
      }),
      ...(abtestSelectedTripType && {
        'Round Trip Ab Result': this.abRoundTrip ? 'a' : 'b',
      }),
      ...(paymentBenefitsAB && {
        'Search Payment Methods Ab Result': this.showPaymentMethods ? 'a' : 'b',
      }),
      ...(discountsAB && {
        'Search Discounts Ab Result': this.getSearchDiscountsABResult(),
      }),
      ...(textPromAB && {
        'Search TextProm Ab Result': this.showTextPromAB ? 'a' : 'b',
      }),
    };
  }

  getSearchDiscountsABResult() {
    const { config } = this.props;
    const { discountSingle, discountRounded, discountOpenTicket } = config;

    if (!this.showDiscountsAB) return 'b';
    if (discountSingle && discountRounded && discountOpenTicket) return 'c';
    return 'a';
  }

  initAbTests() {
    const { config } = this.props;
    const {
      abtestDestinationHit,
      abtestSelectedTripType,
      paymentBenefitsAB,
      discountsAB,
      textPromAB,
    } = config;

    if (abtestDestinationHit) this.abTestDestinationHit();
    if (abtestSelectedTripType) this.abTestSelectedTripType();
    if (paymentBenefitsAB) this.paymentBenefitsAB();
    if (discountsAB) this.discountsAB();
    if (textPromAB) this.textPromAB();
  }

  abTestDestinationHit() {
    const abResult = initTest(50, 'DESTINATION-HIT-AB');
    this.abDestinationHit = abResult === 'a';
  }

  abTestSelectedTripType() {
    const abResult = initTest(50, 'SELECTED-TRIP-TYPE-AB');
    this.abRoundTrip = abResult === 'a';

    this.setState({ tripType: this.abRoundTrip ? 'round' : 'oneWay' });
  }

  paymentBenefitsAB() {
    const abResult = initTest(50, 'paymentBenefitsAB');
    this.showPaymentMethods = abResult === 'a';
  }

  discountsAB() {
    const abResult = initTest(50, 'discountsAB');
    this.showDiscountsAB = abResult === 'a';
  }

  textPromAB() {
    const abResult = initTest(50, 'textPromAB');
    this.showTextPromAB = abResult === 'a';
  }

  emitSearchLoaded() {
    const { config } = this.props;
    const { interestEvent } = config;
    const mixpanelData = this.getMixpanelSearchLoadedProps();
    mixpanelTracker.trackEvent(interestEvent, mixpanelData);
  }

  getInitialPassengersState() {
    const { config, growthbookFeatures } = this.props;
    const {
      passengersForSelector,
      passengersValues: propPassengersValues,
      passengersDropdown,
      passengers,
    } = config;
    const { passengers: qPassengers } = searchFromQuery();

    let customPassengers;
    if (growthbookFeatures?.search_passengers && passengersDropdown && passengersForSelector) {
      customPassengers = getPassengersSelectorInitialState({
        passengers: passengersForSelector,
        searchValues: propPassengersValues,
      });
    }

    const qPassengersDecoded = decodePassengers(qPassengers || passengers);
    return customPassengers || qPassengersDecoded;
  }

  setInitialState() {
    const { config, openTicketText } = this.props;
    const {
      maxDaysSearch,
      accentColor,
      buttonColor,
      buttonShadow,
      buttonBg,
      departureDate,
      returnDate,
      destination,
      origin,
      dateFormat,
      freezeInitial,
    } = config;
    const {
      departureDate: qDepartureDate,
      destination: qDestination,
      origin: qOrigin,
      returnDate: qReturnDate,
    } = searchFromQuery();

    const initialDeparture = qDepartureDate || departureDate;
    const initialDestination = { slug: qDestination || destination };
    const initialOrigin = { slug: qOrigin || origin };
    const initialPassengers = this.getInitialPassengersState();
    const initialReturn = qReturnDate || returnDate;
    const initialDepartureIsOpenTicket = initialDeparture === openTicketText;
    const initialReturnIsOpenTicket = initialReturn === openTicketText;
    const formattedDepartureDate = dayjs(stringToDate(initialDeparture, dateFormat)).format(
      dateFormat.toUpperCase()
    );
    const formattedReturnDate = dayjs(stringToDate(initialReturn, dateFormat)).format(
      dateFormat.toUpperCase()
    );
    this.setState({
      departureDate:
        initialDeparture &&
        validateDate(initialDeparture, maxDaysSearch, openTicketText, dateFormat)
          ? initialDepartureIsOpenTicket
            ? initialDeparture
            : formattedDepartureDate
          : null,
      destination: initialDestination,
      origin: initialOrigin,
      passengers: initialPassengers,
      returnDate:
        initialReturn && validateDate(initialReturn, maxDaysSearch, openTicketText, dateFormat)
          ? initialReturnIsOpenTicket
            ? initialReturn
            : formattedReturnDate
          : null,
      originSearchAttempts: [],
      destinationSearchAttempts: [],
      freezedDestination: initialDestination?.slug && freezeInitial,
      freezedOrigin: initialOrigin?.slug && freezeInitial,
    });

    // Check if initial dates are open trips
    // If departure is open ticket, set hybridTrip to departure
    if (initialDepartureIsOpenTicket) {
      this.setState({
        hybridTrip: 'departure',
      });
    }

    // If return is open ticket, set hybridTrip to return
    // If both are open ticket, set hybridTrip to both
    if (initialReturnIsOpenTicket) {
      if (initialDepartureIsOpenTicket) {
        this.setState({
          hybridTrip: 'both',
        });
      } else {
        this.setState({
          hybridTrip: 'return',
        });
      }
    }

    // Clicktripz initial data
    this.setGtmProperties();

    if (departureDate) {
      this.$get('returnDate').trigger('changeDepartureDate', departureDate);
    }

    if (returnDate) {
      this.$get('departureDate').trigger('changeReturnDate', returnDate);
    }

    this.rootEl.style.setProperty('--accent-500', accentColor);
    this.rootEl.style.setProperty('--button-color', buttonColor);
    this.rootEl.style.setProperty('--button-shadow', buttonShadow);
    this.rootEl.style.setProperty('--button-bg', buttonBg);
  }

  validate() {
    const { maxPassengers } = this.props.config;
    const currentState = this.state;
    const errors = validate(currentState, { maxPassengers });
    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  $get(fieldName) {
    return $(this.rootEl).find(this.fieldClasses[fieldName]);
  }

  setDeparturePicker(picker) {
    this.departurePicker = picker;
  }

  reversePlaces() {
    const { origin, destination } = this.state;
    if (origin.slug && destination.slug) {
      this.setState(prev => ({
        origin: prev.destination,
        destination: prev.origin,
        freezedDestination: prev.freezedOrigin,
        freezedOrigin: prev.freezedDestination,
      }));
    }
  }

  getAutoCompleteUrl() {
    const { config, growthbookFeatures } = this.props;
    const { sourceUrl, line, airline, transporter, forceHttps, filters } = config;
    const sourceUrlToUse = growthbookFeatures?.funnel_version?.sourceUrl || sourceUrl;
    const protocol = window.location.protocol.includes('https') || forceHttps ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${sourceUrlToUse}`);
    if (line) {
      autocompleteUrl.setQueryParam('line', line);
    } else if (airline) {
      autocompleteUrl.setQueryParam('airline', airline);
    } else if (transporter) {
      autocompleteUrl.setQueryParam('transporter', transporter);
    }
    if (filters) {
      autocompleteUrl.setQueryParam('filters', filters);
    }
    return autocompleteUrl;
  }

  onFocusSearchButton() {
    const { originSearchAttempts, destinationSearchAttempts } = this.state;
    const lastOriginSearchAttempted = originSearchAttempts.pop();
    const lastDestinationSearchAttempted = destinationSearchAttempts.pop();
    const additionalProps = {
      'Origin Search Attempts': originSearchAttempts,
      'Origin Search String': lastOriginSearchAttempted,
      'Destination Search Attempts': destinationSearchAttempts,
      'Destination Search String': lastDestinationSearchAttempted,
    };
    getInterestInSearchEventHandler('Search Button', additionalProps)();
  }

  onNoSearchResults(field, value) {
    const { origin } = this.state;

    const eventData = {
      'Search string': value,
      'Field': field,
    };

    if (field === 'destination') {
      eventData.Origin = origin?.slug;
    }

    mixpanelTracker.trackEvent('No search results', eventData);
  }

  render(props, state) {
    const { config, buttonSearch, growthbookFeatures } = this.props;
    const {
      origin,
      tripType,
      errors,
      passengers,
      departureDate,
      returnDate,
      destination,
      geoLocationOrigin,
      coords,
      preferredOrigin,
      preferredDestination,
      autoOpenCalendar,
      autoOpenPassengersSelector,
      freezedDestination,
      freezedOrigin,
    } = state;

    const {
      flatVariant,
      Autocomplete,
      maxDaysSearch,
      maxDaysBulkSearch,
      calendarOpen,
      allowHybridTrip,
      passengersDropdown,
      optInReturn,
      discountSingle,
      discountRounded,
      discountOpenTicket,
      redBadge,
      showOpenTicket,
      brandingCopy,
      widgetTitle,
      showErrors,
      displayType,
      origin: defaultOrigin,
      destination: defaultDestination,
      abtestDestinationHit,
      cardsAvailable,
      oxxoAvailable,
      paypalAvailable,
      coppelpayAvailable,
      departureDatePickerText,
      returnDatePickerText,
      dateFormat,
      whatsappUrl,
      whatsappVariant,
      contrast,
      reverseVariant,
      allowFetch,
      showNearestTerminal,
      groupPlaces,
      geolocationOrigin: geoLocationOriginFeature,
      maxPassengers,
      passengersForSelector,
      seasonPassengersForSelector,
      buttonBg,
    } = config;

    const showWhatsappCTA = growthbookFeatures && growthbookFeatures[`whatsapp_cta`] && whatsappUrl;

    // Checking for the AB test value of the recent search before to showed;
    const isBulkTicket = destination?.meta?.bulkTicket ?? false;
    // Verifies if there is a specific rule with round trip allowance for the selected route. I.e.: bulk ticket.
    const isRoundTripAllowed = destination?.meta?.roundTripAllowed ?? true;
    let hideReturn = (optInReturn && tripType === 'oneWay');
    let showOpenTicketCTAOnReturn = allowHybridTrip && calendarOpen;
    let showDepartureOpenButton = calendarOpen;
    const maxDaysSearchToUse = isBulkTicket ? maxDaysBulkSearch : maxDaysSearch;
    if (!calendarOpen && optInReturn && tripType === 'oneWay') hideReturn = true;

    if (allowHybridTrip && calendarOpen) showOpenTicketCTAOnReturn = true;

    const disableReturnDate = !isRoundTripAllowed && tripType === 'none';

    const autocompleteUrl = this.getAutoCompleteUrl();

    const hasTripSwitch =
      ((showOpenTicket && !calendarOpen) || !hideReturn) && (showOpenTicket || optInReturn);

    const orderDestinationHit = abtestDestinationHit ? this.abDestinationHit : true;
    const showPaymentMethods = cardsAvailable && this.showPaymentMethods;

    const routeError = errors.origin || errors.destination;
    const dateError = errors.departureDate || errors.returnDate;

    const whatsappComponent = showWhatsappCTA && (
      <Fragment>
        {whatsappVariant ? (
          <WhatsappBubble whatsappUrl={whatsappUrl} whatsappCopy="whatsapp_message" />
        ) : (
          <WhatsappCta whatsappUrl={whatsappUrl} whatsappCopy="whatsapp_copy" />
        )}
      </Fragment>
    );

    const paymentMethods = showPaymentMethods && (
      <div className="payment-wrapper">
        <PaymentMethods
          cardsAvailable={cardsAvailable}
          oxxoAvailable={oxxoAvailable}
          paypalAvailable={paypalAvailable}
          coppelpayAvailable={coppelpayAvailable}
        />
      </div>
    );

    const discountsProps = this.showDiscountsAB && {
      discountSingle,
      discountRounded,
      discountOpenTicket,
    };

    const textPromPicker = this.showTextPromAB && {
      departureDatePickerText,
      returnDatePickerText,
    };

    const initialOriginSlug =
      (!this.inputChanged &&
        (preferredOrigin?.slug || (geoLocationOriginFeature && geoLocationOrigin?.slug))) ||
      defaultOrigin;

    const initialDestinationSlug =
      (!this.inputChanged && preferredDestination?.slug) || defaultDestination;

    const hasFooter = whatsappComponent || paymentMethods;
    const showHeaderContainer = hasTripSwitch || showPaymentMethods || whatsappUrl;
    const showReturnDate = !hideReturn && (returnDate || departureDate || this.compactDateUsed) && isRoundTripAllowed;
    const showPassengersDropdown = growthbookFeatures?.search_passengers && passengersDropdown;
    return (
      <Fragment>
        <form
          ref={el => (this.rootEl = el)}
          onSubmit={e => e.preventDefault()}
          className="grid-form"
        >
          {brandingCopy && <BannerBranding copy={brandingCopy} />}

          {widgetTitle && (
            <div className="title-wrapper">
              <WidgetTitle copy={widgetTitle} />
            </div>
          )}

          {showHeaderContainer && (
            <div className="top-wrapper">
              {hasTripSwitch && (
                <div className="switch">
                  <RoundSwitch
                    value={tripType}
                    onChange={newTripType => this.setState({ tripType: newTripType })}
                    showOpenTicket={showOpenTicket}
                    optInReturn={optInReturn}
                    {...discountsProps}
                    redBadge={redBadge}
                  />
                </div>
              )}
              {whatsappUrl ? (
                <Fragment>{whatsappComponent}</Fragment>
              ) : (
                <Fragment>{paymentMethods}</Fragment>
              )}
            </div>
          )}

          <div
            className={`search-form search-form-reverse ${
              reverseVariant ? 'variant-reverse' : ''
            } ${contrast ? 'search-form-contrast' : ''} `}
          >
            <div
              className={`layout-grid-routes layout-grid-routes-switch ${
                routeError ? 'container-error' : ''
              }`}
            >
              <div className="form-field origin">
                <Autocomplete
                  field="origin"
                  sourceUrl={autocompleteUrl.href}
                  onChange={this.onChangeAutocomplete}
                  to={destination && destination.slug}
                  initialPlaceSlug={initialOriginSlug}
                  place={origin}
                  error={Boolean(errors.origin)}
                  placeholder="origin"
                  label="origin"
                  displayType={displayType}
                  onFocus={this.onInputFocus('Origin')}
                  onInputChange={this.onInputChange}
                  onNoSearchResults={this.onNoSearchResults}
                  coordsToOrder={coords}
                  nearestTerminal={geoLocationOrigin}
                  allowFetch={allowFetch}
                  flatVariant={flatVariant}
                  showNearestTerminal={showNearestTerminal}
                  groupPlaces={groupPlaces}
                  disabled={freezedOrigin}
                />
                {showErrors && <span className="fieldError">{errors.origin}</span>}
              </div>
              <div className="form-field-border" />
              <div className="form-field destination">
                <Autocomplete
                  field="destination"
                  sourceUrl={autocompleteUrl.href}
                  onChange={this.onChangeAutocomplete}
                  from={origin && origin.slug}
                  originHasInitialSlug={Boolean(initialOriginSlug)}
                  initialPlaceSlug={initialDestinationSlug}
                  place={destination}
                  error={Boolean(errors.destination)}
                  placeholder="destination"
                  label="destination"
                  displayType={displayType}
                  orderDestinationHit={orderDestinationHit}
                  onFocus={this.onInputFocus('Destination')}
                  onInputChange={this.onInputChange}
                  onNoSearchResults={this.onNoSearchResults}
                  coordsToOrder={coords}
                  allowFetch={allowFetch}
                  didInitialOriginChanged={this.didInitialOriginChanged}
                  groupPlaces={groupPlaces}
                  disabled={freezedDestination}
                />
                {showErrors && <span className="fieldError">{errors.destination}</span>}
              </div>

              <div className="btn-reverse">
                <ReverseRoutesButton
                  onReverse={this.reversePlaces}
                  reverseVariant={reverseVariant}
                />
              </div>
            </div>

            <div
              className={`form-field-date-wrap ${tripType === 'openTicket' ? 'hide' : ''} ${
                !departureDate && !this.compactDateUsed ? 'compact-date' : ''
              }
                ${dateError ? 'container-error' : ''}`}
            >
              <div className={`form-field-date ${hideReturn ? 'border-radius' : ''}`}>
                <Localizer>
                  <Datepicker
                    field="departureDate"
                    onSelect={this.onDepartureDateSelected}
                    onClose={this.onChangeDepartureDate}
                    error={Boolean(errors.departureDate)}
                    placeholder="departure_date"
                    maxDaysSearch={maxDaysSearchToUse}
                    calendarOpen={showDepartureOpenButton}
                    onOpenTicketDate={this.onChangeDepartureToOpenTicket}
                    date={departureDate}
                    onFocus={getInterestInSearchEventHandler('Departure Date')}
                    {...textPromPicker}
                    dateFormat={dateFormat}
                    setDeparturePicker={this.setDeparturePicker}
                    way="departure"
                    onDatePickerCreated={this.onDatePickerCreated}
                    autoOpenCalendar={autoOpenCalendar === 'departure'}
                    label="departure_date"
                    returnDate={returnDate}
                    title={<Text id="title.select_departure_date" />}
                    key={`datepicker-${maxDaysSearchToUse}`}
                  />
                </Localizer>
                {showErrors && <span className="fieldError">{errors.departureDate}</span>}
              </div>

              <div className={`dates-separator ${!showReturnDate ? 'hide' : ''}`} />

              <div className={`form-field-date ${!showReturnDate ? 'hide' : ''}`}>
                <Localizer>
                  <Datepicker
                    field="returnDate"
                    onClose={this.onChangeReturnDate}
                    error={Boolean(errors.returnDate)}
                    hide={hideReturn}
                    label="return_date"
                    placeholder={`return${!optInReturn || calendarOpen ? '_option' : ''}`}
                    maxDaysSearch={maxDaysSearch}
                    calendarOpen={showOpenTicketCTAOnReturn}
                    onOpenTicketDate={this.onChangeReturnToOpenTicket}
                    date={returnDate}
                    onFocus={getInterestInSearchEventHandler('Return Date')}
                    disabled={disableReturnDate}
                    {...textPromPicker}
                    dateFormat={dateFormat}
                    way="return"
                    departureDate={departureDate}
                    onDatePickerCreated={this.onDatePickerCreated}
                    title={<Text id="title.select_return_date" />}
                  />
                </Localizer>
                {showErrors && <span className="fieldError">{errors.returnDate}</span>}
              </div>
            </div>

            {showPassengersDropdown && (
              <div className="form-field sw-dropdown">
                <PassengersSelector
                  passengersList={passengersForSelector}
                  seasonPassengersList={seasonPassengersForSelector}
                  maxPassengers={maxPassengers}
                  onUpdatePassengers={this.onChangePassengers}
                  error={Boolean(errors.passengers)}
                  passengersSelected={passengers}
                  onClick={getInterestInSearchEventHandler('Passengers')}
                  onSubmit={this.onSubmit}
                  onResetPassengers={this.onResetPassengers}
                  autoOpen={autoOpenPassengersSelector}
                />
                {showErrors && <span className="fieldError">{errors.passengers}</span>}
              </div>
            )}

            <div className="button-wrapper">
              <button
                type="submit"
                onClick={this.handleOnClickSubmit}
                className={`search-button ${buttonBg ? 'search-button-bg' : ''} ${
                  contrast ? 'search-button-contrast' : ''
                }`}
                ct-submit="true" // Clicktripz
                onFocus={this.onFocusSearchButton}
              >
                <div
                  className="search-icon"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: selectIcon('searchButton', false),
                  }}
                />
                {buttonSearch}
              </button>
            </div>
          </div>

          {hasFooter && (
            <div
              className={`bottom-wrapper ${showHeaderContainer ? 'bottom-show-for-small' : ''} ${
                cardsAvailable ? '' : 'show-center'
              }`}
            >
              {paymentMethods}
              {whatsappComponent}
            </div>
          )}
        </form>
      </Fragment>
    );
  }
}

export default withText({
  buttonSearch: <Text id="button.search" />,
  openTicketText: <Text id={`label.open_ticket`} />,
})(withWidgetConfig(withGrowthBookFeatures(withUserPreferences(Search))));
